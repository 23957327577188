window.addEventListener('DOMContentLoaded', () => {
  const faqs = document.querySelectorAll('.js-faq-dropdown');

  if (faqs.length === 0) return;

  faqs.forEach((faq) => {
    faq.addEventListener('click', () => {
      const icon = faq.querySelector('.js-faq-icon');
      icon.classList.toggle('-open');
      const answerWrap = faq.nextElementSibling;
      // eslint-disable-next-line no-use-before-define
      slideToggleAnswer(answerWrap);
    });
  });
});

// FAQの開閉アニメーションのためコンテンツのheightを変更するメソッド
function slideToggleAnswer(answerWrap) {
  answerWrap.classList.toggle('-open');
  if (answerWrap.classList.contains('-open')) {
    const answer = answerWrap.querySelector('.js-faq-answer');
    const height = answer.offsetHeight;
    // eslint-disable-next-line no-param-reassign
    answerWrap.style.height = `${height}px`;
  } else {
    // eslint-disable-next-line no-param-reassign
    answerWrap.style.height = '0px';
  }
}
